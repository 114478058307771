import Swal from "sweetalert2";

export default {
  namespaced: true,
  state: {
    data: {},
    isLoading: false,
    isSaving: false,
  },
  mutations: {
    SET_STATE: (state, obj) => {
      state[`${obj.key}`] = obj.value;
    },
  },
  actions: {
    saveStripeConfig: function (context, data) {
      if (data) {
        context.commit("SET_STATE", {
          key: "isSaving",
          value: true,
        });
        this.$http
          .put(`settings/stripe`, data)
          .then((res) => {
            context.commit("SET_STATE", {
              key: "data",
              value: res.data,
            });
            Swal.fire({
              title: "",
              text: "Stripe configuration Saved Successfully",
              icon: "success",
            });
          })
          .catch((err) => {
            if (!err.accessDenied) {
              Swal.fire("Error", err, "error");
            }
          })
          .finally(() => {
            context.commit("SET_STATE", {
              key: "isSaving",
              value: false,
            });
          });
      }
    },
    getStripeConfig: function (context) {
      context.commit("SET_STATE", {
        key: "isLoading",
        value: true,
      });
      this.$http
        .get(`settings/stripe`)
        .then((res) => {
          context.commit("SET_STATE", {
            key: "data",
            value: res.data,
          });
        })
        .catch((err) => {
          if (!err.accessDenied) {
            Swal.fire("Error", err, "error");
          }
        })
        .finally(() => {
          context.commit("SET_STATE", {
            key: "isLoading",
            value: false,
          });
        });
    },
  },
};
