import Swal from "sweetalert2";

export default {
  namespaced: true,
  state: {
    data: [],
    isLoading: false,
    isSaving: false,
    categories: {
      data: [],
      isLoading: false,
    },
  },
  mutations: {
    SET_STATE: (state, obj) => {
      state[`${obj.key}`] = obj.value;
    },
    SET_CATEGORIES_STATE: (state, obj) => {
      state.categories[`${obj.key}`] = obj.value;
    },
  },
  actions: {
    create: function (context, payload) {
      context.commit("SET_STATE", { key: "isSaving", value: true });
      return new Promise((resolve, reject) => {
        this.$http
          .post(`first_hx/${payload.userId}/create`, {
            type: payload.type,
            send_type: payload.sendType,
            phone_number: payload.phoneNumber,
            domain: payload.domain,
          })
          .then((result) => {
            context.commit("SET_STATE", { key: "isSaving", value: false });
            resolve(result.data);
          })
          .catch((err) => {
            context.commit("SET_STATE", { key: "isSaving", value: false });
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: (err.data || err.response.data).message,
                icon: "error",
              });
            }
            reject(err);
          });
      });
    },
    update: function (_, id) {
      let vm = this;
      return new Promise((resolve) => {
        vm.$http
          .post(`intake-forms/${id}/update`)
          .then((res) => {
            resolve(res?.data?.data);
          })
          .catch((err) => {
            if (!err.accessDenied) {
              resolve(false);
            }
          });
      });
    },
    editReport: function (_, payload) {
      let vm = this;
      return new Promise((resolve) => {
        vm.$http
          .post(`first_hx/${payload.id}/edit-report`, {
            edited_report: payload.edited_report,
          })
          .then((res) => {
            resolve(res?.data?.data);
          })
          .catch((err) => {
            if (!err.accessDenied) {
              resolve(false);
            }
          });
      });
    },
    all: function (context, userId) {
      let vm = this;
      return new Promise((resolve) => {
        context.commit("SET_STATE", { key: "isLoading", value: true });
        vm.$http
          .get(`first_hx/${userId}/all`)
          .then((res) => {
            context.commit("SET_STATE", { key: "isLoading", value: false });
            context.commit("SET_STATE", { key: "data", value: res.data.data });
            resolve(res.data.data);
          })
          .catch((err) => {
            context.commit("SET_STATE", { key: "isLoading", value: false });
            if (!err.accessDenied) {
              resolve(false);
            }
          });
      });
    },
    getIntakeReport: function (_, id) {
      let vm = this;
      return new Promise((resolve) => {
        vm.$http
          .get(`first_hx/${id}/details`)
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            if (!err.accessDenied) {
              resolve(false);
            }
          });
      });
    },
    getIntakeById: function (_, id) {
      let vm = this;
      return new Promise((resolve) => {
        vm.$http
          .get(`first_hx/${id}/get-intake`)
          .then((res) => {
            resolve(res?.data?.data);
          })
          .catch((err) => {
            if (!err.accessDenied) {
              resolve(false);
            }
          });
      });
    },
    getLastCompletedIntake: function (_, userId) {
      let vm = this;
      return new Promise((resolve) => {
        vm.$http
          .get(`first_hx/${userId}/last-completed`)
          .then((res) => {
            resolve(res?.data?.data);
          })
          .catch((err) => {
            if (!err.accessDenied) {
              resolve(false);
            }
          });
      });
    },
    getIntakeByKey: function (_, key) {
      let vm = this;
      return new Promise((resolve) => {
        vm.$http
          .get(`intake-forms/${key}`)
          .then((res) => {
            resolve(res?.data?.data);
          })
          .catch((err) => {
            if (!err.accessDenied) {
              resolve(false);
            }
          });
      });
    },
    sendIntakeNotification: function (_, payload) {
      return new Promise((resolve) => {
        this.$http
          .post(`first_hx/${payload.intakeId}/notify`, {
            send_type: payload.sendType,
            phone_number: payload.phoneNumber,
            domain: payload.domain,
          })
          .then((result) => {
            Swal.fire({
              title: "Success",
              text: result.data?.message,
              icon: "success",
            });
            resolve(result.data);
          })
          .catch((err) => {
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: (err.data || err.response.data).message,
                icon: "error",
              });
            }
            resolve(false);
          });
      });
    },
    generateSummary: function (_, payload) {
      let vm = this;
      return new Promise((resolve) => {
        vm.$http
          .get(
            `intake-forms/${payload.id}/generate-summary?type=${payload.type}`
          )
          .then((res) => {
            resolve(res?.data?.data);
          })
          .catch((err) => {
            if (!err.accessDenied) {
              resolve(false);
            }
          });
      });
    },
    getCategories: function (context, payload) {
      let vm = this;
      return new Promise((resolve) => {
        context.commit("SET_CATEGORIES_STATE", {
          key: "isLoading",
          value: true,
        });
        vm.$http
          .get(
            `first_hx/categories${
              payload && payload.status ? "?status=" + payload.status : ""
            }`
          )
          .then((res) => {
            context.commit("SET_CATEGORIES_STATE", {
              key: "isLoading",
              value: false,
            });
            context.commit("SET_CATEGORIES_STATE", {
              key: "data",
              value: res.data.data,
            });
            resolve(res.data.data);
          })
          .catch((err) => {
            context.commit("SET_CATEGORIES_STATE", {
              key: "isLoading",
              value: false,
            });
            if (!err.accessDenied) {
              resolve(false);
            }
          });
      });
    },
    createCategory: function (_, payload) {
      return new Promise((resolve) => {
        this.$http
          .post("first_hx/categories", payload)
          .then((result) => {
            resolve(result.data.data);
          })
          .catch((err) => {
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: (err.data || err.response.data).message,
                icon: "error",
              });
            }
            resolve(false);
          });
      });
    },
    updateCategory: function (_, payload) {
      return new Promise((resolve) => {
        this.$http
          .put(`first_hx/categories/${payload.id}`, payload)
          .then((result) => {
            resolve(result.data.data);
          })
          .catch((err) => {
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: (err.data || err.response.data).message,
                icon: "error",
              });
            }
            resolve(false);
          });
      });
    },
    deleteCategory: function (_, id) {
      return new Promise((resolve) => {
        this.$http
          .delete(`first_hx/categories/${id}`)
          .then((res) => {
            Swal.fire({
              title: "Success",
              text: res.data.message,
              icon: "success",
            });
            resolve(true);
          })
          .catch((err) => {
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: (err.data || err.response.data).message,
                icon: "error",
              });
            }
            resolve(false);
          });
      });
    },
  },
};
